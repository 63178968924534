@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body {
  font-family: 'Source Sans Pro', sans-serif;
}

.smooth-gradient {
  position: relative;
}

.smooth-gradient::before,
.smooth-gradient::after {
  content: '';
  position: absolute;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 1rem;
}

.smooth-gradient::before {
  background: linear-gradient(0deg, transparent, #0f1d2e);
  top: 0;
}

.smooth-gradient::after {
  background: linear-gradient(0deg, #0f1d2e, transparent);
  bottom: 0;
}
